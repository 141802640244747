body {
  color: #343a40;
  overflow-x: hidden;
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
.jumbotron {
  margin-bottom: 0;
  background-color: #fff;
  color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: url(/81c5f61e01fa5270901a5e1a10928693.jpg) repeat-y center center;
  background-size: 100%;
  height: 400px;
}

.jumbotron p.lead {
  padding-top: 10rem;
  color: white;
}

@media (max-width: 989px) {
  .jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background: url(/81574bd34485bee0fec179234b29d303.jpg) repeat-y center center;
    background-size: 100%;
    height: 300px;
  }

  .jumbotron p.lead {
    padding-top: 4rem;
  }
}
@media (max-width: 767px) {
  .jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background: url(/ca2fb3d9cd7a729144979c82184972a6.jpg) repeat-y center center;
    background-size: 100%;
    height: 300px;
  }

  .jumbotron p.lead {
    padding-top: 4rem;
  }
}
@media (max-width: 575px) {
  .jumbotron {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: url(/e8dd7225b5712c9c50bb48242a39c451.jpg) repeat-y center center;
    height: 200px;
  }

  .jumbotron p.lead {
    padding-top: 0.5rem;
  }
}
.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron h1 {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: white;
  border-radius: 30px;
  padding: 10px;
}

.search_input {
  color: #343a40;
  border: 0;
  outline: 0;
  background: none;
  width: 450px;
  caret-color: #006390;
  line-height: 40px;
  padding: 0 10px;
  transition: width 0.4s linear;
}

@media (max-width: 575px) {
  .search_input {
    color: #343a40;
    border: 0;
    outline: 0;
    background: none;
    width: 70vw;
    caret-color: #006390;
    line-height: 40px;
    padding: 0 10px;
    transition: width 0.4s linear;
  }
}
.search_icon {
  height: 40px;
  width: 40px;
  align-items: center;
  border-radius: 50%;
  color: #343a40 !important;
  cursor: pointer;
  text-decoration: none;
}

section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #006390;
  margin-bottom: 50px;
  text-transform: uppercase;
}

main > div {
  min-height: 55vh;
}

#footer {
  background: #006390 !important;
  font-size: small;
  padding: 30px 0px;
}

#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}

#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

ul.social li {
  padding: 3px 0;
}

ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}

#footer ul.quick-links li a {
  color: #ffffff;
}

#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer ul.quick-links li:hover {
  padding: 3px 0;
  font-weight: 700;
}

#footer ul.quick-links li a i {
  margin-right: 5px;
}

#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}
/* Make the tag position relative to the figure */
.figure.tag {
  position: relative;
}

/* set the base styles all tags should use */
.figure.tag::before {
  position: absolute;
  top: 5%;
  display: block;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: bold;
}

/* Specific variations for different tags */
.figure.tag-featured::before {
  content: "Featured";
  background: gold;
  color: #000;
}

.figure.tag-recent::before {
  content: "Recent";
  background: green;
}

.figure.tag-out::before {
  content: "Out of Stock";
  background: #e2e2e2;
  border: #ccc;
  color: #444;
  top: 42%;
  right: 0;
  left: 0;
  width: 30%;
  margin: 0 auto;
  text-align: center;
}

.mh-8 {
  min-height: 8em;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unstyled-link {
  color: black;
}

.unstyled-link:hover {
  text-decoration: none;
  color: black;
}

.pre-line-white-space {
  white-space: pre-line;
}

.figure-img {
  object-fit: contain;
}

.right {
  right: 0px !important;
}

.pointer {
  cursor: pointer;
}

#login-modal {
  position: fixed;
  width: 350px;
  top: 0;
  transition: 0.5s all ease-in-out;
  right: -380px;
  box-shadow: -5px 5px 15px 0px #616161;
  z-index: 9999;
  height: 100vh;
  background: #fff;
}

.overlay-content {
  position: relative;
  top: 14%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.form-width {
  padding: 5% 10%;
}

.login-input {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
}

.login-input:focus {
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.mt-32px {
  margin-top: 32px;
}

.btn-login {
  background: #2966ce;
  color: #fff;
}

.btn-login:focus {
  outline: none;
  box-shadow: none;
}

button.close {
  margin-right: 8px;
}

.min-height24 {
  min-height: 24px;
}

.font-size14 {
  font-size: 14px;
}

.form-box-shadow {
  border: 1px solid #ced4da;
  padding: 20px 15px;
  box-shadow: 0 0 6px 0 rgba(1, 1, 1, 0.3);
}

.form-box-shadow label {
  font-size: 12px;
  margin-bottom: 0;
}

.form-box-shadow label.tip {
  font-size: x-small;
  margin-bottom: 1em;
}

.text-small {
  border-bottom-style: solid;
  border-top-style: solid;
  border-width: 1px;
  font-size: small;
}